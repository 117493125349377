import Cookies from 'js-cookie';

export default {
  namespaced: true,
  state: {
    errors: null,
    patient: null,
    signer: null,
    user: null,
  },
  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = null;
    },
    setPatient(state, patient) {
      state.patient = patient;
      Cookies.set('patient', JSON.stringify(patient), { secure: true });
    },
    clearPatient(state) {
      state.patient = null;
    },
    setSigner(state, signer) {
      state.signer = signer;
      Cookies.set('signer', JSON.stringify(signer), { secure: true });
    },
    setUser(state, userData) {
      state.user = userData.user;
      Cookies.set('user', JSON.stringify(userData.user), { secure: true });
      Cookies.set('access_token', userData.access_token, { secure: true });
    },
    clearSigner(state) {
      state.signer = null;
    },
  },
  actions: {
    setErrors({ commit, state }, errors) {
      if (errors[0] == 'These credentials do not match our records.') {
        errors[0] =
          'These credentials do not match our records. Please contact the facility to confirm your profile was set up correctly.';
      }
      commit('setErrors', errors);
    },
    clearErrors({ commit }) {
      commit('clearErrors');
    },
    setPatient({ commit, state }, patient) {
      commit('setPatient', patient);
    },
    clearPatient({ commit }) {
      commit('clearPatient');
    },
    setSigner({ commit, state }, signer) {
      commit('setSigner', signer);
    },
    setUser({ commit, state }, userData) {
      commit('setUser', userData);
    },
    clearSigner({ commit }) {
      commit('clearPatient');
    },
  },
  getters: {
    errors(state) {
      return state.errors;
    },
    isAuthenticated(state) {
      return !_.isNull(state.patient) || !_.isNull(state.signer);
    },
    patient(state) {
      return state.patient;
    },
    hasPatient(state) {
      return !_.isNull(state.patient);
    },
    signer(state) {
      return state.signer;
    },
    hasSigner(state) {
      return !_.isNull(state.signer);
    },
  },
};
